<script setup>
import MainButton from '@/components/MainButton.vue'

const props = defineProps({
  product: {
    type: Object,
    required: false
  },
  title: {
    type: String,
    default: 'Potrzebujesz pomocy?'
  },
  roundedCorners: {
    type: Boolean,
    default: false
  },
  buttonText: {
    type: String,
    default: 'Zamów konsultację'
  },
  showButton: {
    type: Boolean,
    default: true
  }
})

const handleBookCall = () => {
  window.open(props.product?.pipedrive_user?.calendar_link, '_blank')
}
</script>

<template>
  <div
    class="bg-mainGrey-700 text-white p-6"
    :class="{ 'rounded-xl': roundedCorners, 'rounded-none': !roundedCorners }"
  >
    <p class="pb-1 font-normal uppercase border-b text-white border-b-mainGrey-600">
      {{ props.title }}
    </p>
    <div class="text-xs my-4">
      <div class="flex flex-row items-center">
        <img
          class="h-[70px] w-[70px] mr-2 bg-white rounded-full"
          :src="props.product?.pipedrive_user?.image?.url"
        />
        <div>
          <p class="font-medium">
            {{ props.product?.pipedrive_user?.first_name }}
            {{ props.product?.pipedrive_user?.last_name }}
          </p>
          <p class="font-light">{{ props.product?.pipedrive_user?.phone }}</p>
          <p class="font-light">{{ props.product?.pipedrive_user?.user_email }}</p>
        </div>
      </div>
    </div>
    <MainButton
      v-if="props.showButton"
      @click="handleBookCall"
      variant="secondary"
      class="!px-6 text-xs !py-2 self-starts"
    >
      {{ props.buttonText }}
    </MainButton>
  </div>
</template>
