import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import {
  refreshUserData,
  signIn as apiSignIn,
  registerUser as apiRegisterUser,
  updateUser as apiUpdateUser,
  resetPassword as apiResetPassword,
  createNewPassword as apiCreateNewPassword
} from '@/lib/requests'

export const useAuthStore = defineStore('auth', () => {
  const router = useRouter()
  const authToken = ref(localStorage.getItem('authToken'))
  const isSignedIn = computed(() => authToken.value !== null)
  const errorMessage = ref(null)
  const user = ref(null)

  async function refreshUserDataStore() {
    const response = await refreshUserData(authToken.value)
    const data = await response.json()
    if (!response.ok) {
      localStorage.removeItem('authToken')
      localStorage.removeItem('dataMe')
      return signOut()
    }
    user.value = data
  }

  async function signIn(email, password, rememberMe) {
    const response = await apiSignIn(email, password, rememberMe)
    if (!response.ok) {
      const data = await response.json()
      errorMessage.value = data
    }

    if (response.status === 200) {
      const data = await response.json()
      errorMessage.value = null

      authToken.value = data.authToken
      localStorage.setItem('authToken', data.authToken)
      refreshUserDataStore()
    }
    return response
  }

  function signOut() {
    authToken.value = null
    localStorage.removeItem('authToken')
    localStorage.removeItem('dataMe')

    router.push('/')
    window.location.reload()
  }

  async function registerUser(data) {
    const response = await apiRegisterUser(data)
    if (!response.ok) {
      const data = await response.json()
      errorMessage.value = data
    }
    return response
  }

  async function updateUser(data) {
    const response = await apiUpdateUser(data, authToken.value)
    if (response.status === 200) {
      const data = await response.json()
      localStorage.setItem('dataMe', JSON.stringify(data))
    }
  }

  async function resetPassword(data) {
    return await apiResetPassword(data)
  }

  async function createNewPassword(data) {
    return await apiCreateNewPassword(data)
  }

  return {
    authToken,
    isSignedIn,
    signIn,
    signOut,
    errorMessage,
    user,
    registerUser,
    updateUser,
    refreshUserDataStore,
    resetPassword,
    createNewPassword
  }
})
