const chartOptions = {
  doughnut: {
    responsive: true,
    cutout: '80%',
    radius: '100%',
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: '#FFF',
          usePointStyle: true,
          pointStyle: 'circle'
        }
      }
    }
  },
  pie: {
    responsive: true,
    radius: '100%',
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: '#FFF',
          usePointStyle: true,
          pointStyle: 'circle'
        }
      }
    }
  }
}

export { chartOptions }
