<script setup>
import { ref, onMounted } from 'vue'
import { useContactStore } from '@/stores/contact'
import { useSummaryStore } from '@/stores/summary'
import BookCall from '@/components/product/info/BookCall.vue'
import { useRoute } from 'vue-router'
const contactStore = useContactStore()
const summaryStore = useSummaryStore()
const currentYear = ref(new Date().getFullYear())
const route = useRoute()

const menuLinks = ref([
  { to: '/', label: 'Strona główna' },
  { to: '/wizy', label: 'Wizy' },
  { to: '/legalizacje', label: 'Legalizacje' },
  { to: '/dokumenty-i-uslugi', label: 'Dokument i usługi' }
])

const companyLinks = ref([
  { to: '/informacje/nasz-zespol', label: 'Nasz zespół' },
  { to: '/informacje/dane-do-platnosci', label: 'Dane do płatności' },
  { to: '/informacje/polityka-prywatnosci', label: 'Polityka prywatności' },
  { to: '/informacje/regulamin', label: 'Regulamin' },
  { to: '/informacje/kontakt', label: 'Kontakt' }
])

const accountLinks = ref([
  { to: '/rejestracja', label: 'Rejestracja' },
  { to: '/logowanie', label: 'Logowanie' }
])
onMounted(() => {})
/* <div class="fixed bottom-0 left-0 w-full bg-mainGrey-800 text-white p-4">
  <div class="flex justify-between">
    <p>Podsumowanie</p>
    <p>
      <!-- {{ summaryStore.summary }} -->
    </p>
  </div>
  <div class="text-black text-xs bg-mainGrey-700 hidden bottom-0 left-0 w-full">
    {{ summaryStore.summary }}
  </div>
</div> */
</script>

<template>
  <BookCall
    v-if="route.path.includes('krok-2')"
    :product="contactStore.contact"
    class="mt-4 md:hidden"
  />
  <footer
    class="w-full px-3 py-8 md:py-16 md:px-6 text-mainGrey-300 bg-mainGrey-600 flex flex-col items-center min-h-[20rem]"
  >
    <div
      class="justify-between w-full h-full px-4 mx-auto md:px-10 lg:px-20 max-w-7xl grid grid-cols-1 sm:grid-cols-1 md:grid-cols-5 gap-10"
    >
      <div class="col-span-1 md:col-span-2">
        <img src="@/assets/logo-notberg.svg" alt="logo" class="mr-8 w-[6rem] mb-5" />
        <div class="text-xs text-left">
          Załatwimy Twoje sprawy w ambasadach, <br />ministerstwach i innych urzędach!<br /><br />
          Platforma od WizaSerwis w wersji beta. W razie problemu prosimy o kontakt na chacie.
        </div>
      </div>

      <div class="flex flex-col">
        <span class="text-sm text-white uppercase font-normal mb-4">MENU</span>
        <div v-for="link in menuLinks" :key="link.to">
          <router-link :to="link.to" class="mb-2 text-xs hover:text-gray-300 md:mb-0">
            {{ link.label }}
          </router-link>
        </div>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-white uppercase font-normal mb-4">Firma</span>
        <div v-for="link in companyLinks" :key="link.to">
          <router-link :to="link.to" class="mb-2 text-xs hover:text-gray-300 md:mb-0">
            {{ link.label }}
          </router-link>
        </div>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-white uppercase font-normal mb-4">Rejestracja</span>
        <div v-for="link in accountLinks" :key="link.to">
          <router-link :to="link.to" class="mb-2 text-xs hover:text-gray-300 md:mb-0">
            {{ link.label }}
          </router-link>
        </div>
      </div>
      <div
        class="items-center justify-between w-full py-8 md:flex-row md:col-span-5 border-t border-mainGrey-300"
      >
        <div class="items-center justify-between w-full md:flex-row">
          <div class="text-xs text-left">
            © {{ currentYear }} WizaSerwis. Wszelkie prawa zastrzeżone.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
