export default {
  legacy: false,
  locale: 'pl',
  fallbackLocale: 'en',
  messages: {
    pl: {
      global:{
        legalisation: 'Legalizacja',
        legalisations: 'Legalizacje',
        visa: 'Wiza',
        visas: 'Wizy',
        document: 'Dokument',
        documents: 'Dokumenty',
        apostille: 'Apostille',
      },
      message: {
        hello: 'hejka'
      },
      chart: {
        labels: {
          not_required: 'Niewymagana',
          required: 'Wymagana',
          visa_free: 'Bez wizy',
          on_arrival: 'W dniu przyjazdu'
        }
      },
      productTypes: {
        visa: 'Wiza',
        document: 'Dokument',
        legalisation: 'Legalizacja'
      }
    },
    en: {
      global:{
        legalisation: 'Legalisation',
        legalisations: 'Legalisations',
        visa: 'Visa',
        visas: 'Visas',
        document: 'Document',
        documents: 'Documents',
        apostille: 'Apostille'
      },
      message: {
        hello: 'hello world'
      },
      chart: {
        labels: {
          not_required: 'Not required',
          required: 'Required',
          visa_free: 'Wisa free',
          on_arrival: 'On arrival'
        }
      },
      productTypes: {
        visa: 'Visa',
        document: 'Document',
        legalisation: 'Legalisation'
      }
    }
  }
}
