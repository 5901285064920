const xanoUrl = `${import.meta.env.VITE_XANO_API_URL}`

async function makeRequest(url, method, data, authToken) {
  const headers = {
    'Content-Type': 'application/json',
    'X-Data-Source': import.meta.env.VITE_X_DATA_SOURCE,
    'X-Branch': import.meta.env.VITE_X_BRANCH,
    'Access-Control-Allow-Origin': '*'
  }

  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`
  }

  return await fetch(url, {
    method: method,
    headers: headers,
    body: data ? JSON.stringify(data) : null
  })
}

async function newOrder(data) {
  return await makeRequest(`${xanoUrl}/new_order`, 'POST', data)
}

async function checkOrder(data) {
  return await makeRequest(`${xanoUrl}/check_order`, 'POST', data)
}
async function orderPackage(data) {
  return await makeRequest(`${xanoUrl}/order_package`, 'POST', data)
}
async function orderServices(data) {
  return await makeRequest(`${xanoUrl}/order_services`, 'POST', data)
}
async function orderContactData(data) {
  return await makeRequest(`${xanoUrl}/order_contact_data`, 'POST', data)
}

async function visaOrderSummary(data) {
  return await makeRequest(`${xanoUrl}/visa_order_summary`, 'POST', data)
}
async function legalisationsOrderSummary(data) {
  return await makeRequest(`${xanoUrl}/legalisations_order_summary`, 'POST', data)
}
async function documentsOrderSummary(data) {
  return await makeRequest(`${xanoUrl}/document_order_summary`, 'POST', data)
}

async function getCountries() {
  return await makeRequest(`${xanoUrl}/visas_countries`, 'GET')
}

async function getVisas(name) {
  return await makeRequest(`${xanoUrl}/visas/${name}`, 'GET')
}

async function getVisaTypes(id) {
  return await makeRequest(`${xanoUrl}/visa_types/${id}`, 'GET')
}

async function getProduct(product_id) {
  return await makeRequest(`${xanoUrl}/products/${product_id}`, 'GET')
}

async function getOrderSummary(data) {
  return await makeRequest(`${xanoUrl}/order_summary`, 'POST', data)
}

async function createNewFormInOrder(data) {
  return await makeRequest(`${xanoUrl}/orders_forms`, 'POST', data)
}
async function createNewCompanyJoinRequests(data, authRequired) {
  return await makeRequest(`${xanoUrl}/company_join_requests`, 'POST', data, authRequired)
}

async function getPrivateFile(data, authRequired) {
  return await makeRequest(`${xanoUrl}/show_file`, 'POST', data, authRequired)
}

async function confirmAccount(token) {
  return await makeRequest(`${xanoUrl}/confirm_account${token}`, 'GET')
}

async function confirmOrder(data) {
  return await makeRequest(`${xanoUrl}/confirm_order`, 'PUT', data)
}

async function deleteFilloutForm(data) {
  return await makeRequest(`${xanoUrl}/delete_fillout_form`, 'POST', data)
}

async function refreshUserData(authToken) {
  return await makeRequest(`${xanoUrl}/auth/me`, 'GET', null, authToken)
}

async function signIn(email, password, rememberMe) {
  return await makeRequest(`${xanoUrl}/auth/login`, 'POST', { email, password, rememberMe })
}

async function registerUser(data) {
  return await makeRequest(`${xanoUrl}/auth/signup`, 'POST', data)
}

async function updateUser(data, authToken) {
  return await makeRequest(`${xanoUrl}/user`, 'PUT', data, authToken)
}

async function resetPassword(data) {
  return await makeRequest(`${xanoUrl}/auth/reset_password`, 'POST', data)
}

async function createNewPassword(data) {
  return await makeRequest(`${xanoUrl}/auth/create_new_password`, 'POST', data)
}

async function getOrdersAccount(authToken) {
  return await makeRequest(`${xanoUrl}/auth/orders`, 'GET', null, authToken)
}

async function getOrdersAccountDetails(uuid, authToken) {
  return await makeRequest(`${xanoUrl}/auth/orders_details/${uuid}`, 'GET', null, authToken)
}

async function getDocuments() {
  return await makeRequest(`${xanoUrl}/documents`, 'GET')
}

async function getDocumentsTypes(type_id) {
  return await makeRequest(`${xanoUrl}/documents_types/${type_id}`, 'GET')
}

async function getArticles() {
  return await makeRequest(`${xanoUrl}/articles`, 'GET')
}

async function getArticlesDetails(slug) {
  return await makeRequest(`${xanoUrl}/articles/${slug}`, 'GET')
}

async function getSocialMediaPosts() {
  return await makeRequest(`${xanoUrl}/social_media_posts`, 'GET')
}

async function getChartVisaKinds() {
  return await makeRequest(`${xanoUrl}/chart_visa_kinds`, 'GET')
}

async function getStaticPage(slug) {
  return await makeRequest(`${xanoUrl}/static_pages/${slug}`, 'GET')
}

async function getLegalisationsCountries() {
  return await makeRequest(`${xanoUrl}/legalisations_countries`, 'GET')
}

async function getLegalisationsDocuments(data) {
  return await makeRequest(`${xanoUrl}/legalisations_documents`, 'POST', data)
}

async function addLegalisationsDocuments(data) {
  return await makeRequest(`${xanoUrl}/legalisations_add_documents`, 'POST', data)
}

async function getLegalisationsDocumentDetails(data) {
  return await makeRequest(`${xanoUrl}/legalisations_document_details`, 'POST', data)
}

async function getLegalizationsOrderedDocuments(data) {
  return await makeRequest(`${xanoUrl}/legalizations_ordered_documents`, 'POST', data)
}

async function deleteLegalizationsOrderedDocuments(data) {
  return await makeRequest(`${xanoUrl}/delete_legalizations_ordered_documents`, 'POST', data)
}
async function getLegalisationsByCountry(name) {
  return await makeRequest(`${xanoUrl}/legalisations/${name}`, 'GET')
}
async function getDocumentsTypesName(type_name) {
  return await makeRequest(`${xanoUrl}/documents_types_name/${type_name}`, 'GET')
}

async function partnershipApplication(authToken) {
  return await makeRequest(`${xanoUrl}/partnership_application`, 'GET', null, authToken)
}

export {
  newOrder,
  checkOrder,
  orderPackage,
  orderServices,
  orderContactData,
  visaOrderSummary,
  legalisationsOrderSummary,
  documentsOrderSummary,
  getCountries,
  getVisas,
  getVisaTypes,
  getProduct,
  getOrderSummary,
  getOrdersAccount,
  getOrdersAccountDetails,
  createNewFormInOrder,
  createNewCompanyJoinRequests,
  getPrivateFile,
  confirmAccount,
  confirmOrder,
  deleteFilloutForm,
  refreshUserData,
  signIn,
  registerUser,
  updateUser,
  resetPassword,
  createNewPassword,
  getDocumentsTypes,
  getDocuments,
  getArticles,
  getArticlesDetails,
  getSocialMediaPosts,
  getChartVisaKinds,
  getStaticPage,
  getLegalisationsCountries,
  getLegalisationsDocuments,
  addLegalisationsDocuments,
  getLegalisationsDocumentDetails,
  getLegalizationsOrderedDocuments,
  deleteLegalizationsOrderedDocuments,
  getLegalisationsByCountry,
  getDocumentsTypesName,
  partnershipApplication
}
