<script setup>
import { RouterView, useRoute } from 'vue-router'
import { computed } from 'vue'
import NavBar from '@/components/NavBar.vue'
import MainFooter from '@/components/MainFooter.vue'

const route = useRoute()

const hideNavbar = computed(() => {
  const authPaths = ['register', 'login', 'reset-password', 'create-new-password']
  return !authPaths.includes(route.name)
})

const devBranch = computed(() => {
  return import.meta.env.VITE_X_BRANCH === 'v2' ? true : false
})
</script>

<template>
  <div class="bg-mainGrey-100 md:bg-white">
    <div
      class="bg-mainGrey-300 z-50 h-8 text-center text-mainGrey-700 text-xs flex items-center justify-center left-0 w-full px-4"
    >
      Platforma od WizaSerwis w wersji beta. W razie problemu prosimy o kontakt na chacie.
    </div>
    <NavBar v-if="hideNavbar" />
    <div
      :class="{
        'w-full relative m-auto my-6 md:my-12 max-w-7xl px-5 lg:px-20 ms:px-10 min-h-screen':
          hideNavbar
      }"
    >
      <RouterView />
    </div>
    <MainFooter v-if="hideNavbar" />
  </div>
  <div
    v-if="devBranch"
    class="bg-red-500 z-50 h-6 text-center text-white text-xs flex items-center justify-center sticky bottom-0 left-0 w-full"
  >
    Dev
  </div>
</template>
