<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import MainButton from '@/components/MainButton.vue'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const swiperModules = [Navigation, Pagination, Autoplay]

const banners = [
  {
    image: '@/assets/baner-1.jpg',
    title: 'Sprawdź, dlaczego warto z nami pracować.',
    description:
      'Oszczędzamy Twój czas, zajmując się formalnościami, byś mógł skupić się na biznesie. Z nami działasz sprawniej na rynkach zagranicznych.',
    button: 'Sprawdź',
    link: '/informacje/dlaczego-warto-z-nami-pracowac'
  },
  {
    image: '@/assets/baner-2.jpg',
    title: 'Pomagamy firmom sprawnie uzyskać wizy i dokumenty',
    description:
      'aby mogły działać bez przeszkód w Polsce i za granicą. Legalizujemy dokumenty, dzięki czemu są one uznawane na całym świecie.',
    button: 'Więcej',
    link: '/informacje/jak-pracujemy'
  }
]
</script>

<template>
  <div class="mb-16">
    <swiper
      :modules="swiperModules"
      :slides-per-view="1"
      :loop="true"
      :pagination="{ clickable: true }"
      :autoplay="{ delay: 5000, disableOnInteraction: false }"
      class="mySwiper"
    >
      <swiper-slide>
        <div
          class="w-full h-[420px] md:h-[340px] rounded-md bg-image bg-[url('@/assets/baner-main.jpg')] bg-cover bg-center flex justify-left items-center relative"
        >
          <div class="w-full h-full bg-black opacity-40 absolute rounded-md"></div>
          <div
            class="text-white text-left sm:text-[28px] lg:text-[36px] text-[28px] leading-[36px] lg:leading-[36px] font-semibold relative z-10 px-8 md:px-14"
          >
            Notberg to nowa<br />odsłona WizaSerwis.
            <div class="text-sm font-light max-w-[500px] mt-4 w-[80%] md:w-[80%]">
              Zmieniamy się dla Państwa, aby oferować lepsze usługi. Wszystko sprawniej, lepiej i
              jeszcze bardziej dopasowane do wymagań stawianych przez biznes.
            </div>
            <!-- <router-link to="/informacje/dlaczego-warto-z-nami-pracowac">
              <MainButton class="font-normal mt-8">Sprawdź</MainButton>
            </router-link> -->
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="w-full h-[420px] md:h-[340px] rounded-md bg-image bg-[url('@/assets/baner-1.jpg')] bg-cover bg-center flex justify-left items-center relative"
        >
          <div class="w-full h-full bg-black opacity-40 absolute rounded-md"></div>
          <div
            class="text-white text-left sm:text-[28px] lg:text-[36px] text-[28px] leading-[36px] lg:leading-[36px] font-semibold relative z-10 px-8 md:px-14"
          >
            Sprawdź, dlaczego<br />warto z nami pracować.
            <div class="text-sm font-light max-w-[500px] mt-4 w-[80%] md:w-[80%]">
              Oszczędzamy Twój czas, zajmując się formalnościami, byś mógł skupić się na biznesie. Z
              nami działasz sprawniej na rynkach zagranicznych.
            </div>
            <router-link to="/informacje/dlaczego-warto-z-nami-pracowac">
              <MainButton class="font-normal mt-8">Sprawdź</MainButton>
            </router-link>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="w-full h-[420px] md:h-[340px] rounded-md bg-image bg-[url('@/assets/baner-2.jpg')] bg-cover bg-right flex justify-left items-center relative"
        >
          <div
            class="text-black text-left sm:text-[28px] lg:text-[36px] text-[28px] leading-[36px] lg:leading-[36px] font-semibold relative z-10 px-8 md:px-14"
          >
            Pomagamy firmom<br />sprawnie uzyskać<br />wizy i dokumenty,
            <div class="text-sm font-light max-w-[800px] mt-4 w-[80%] md:w-[80%]">
              aby mogły działać bez przeszkód w Polsce i za granicą.<br />Legalizujemy dokumenty,
              dzięki czemu są one uznawane na całym świecie.
            </div>
            <router-link to="/informacje/jak-pracujemy">
              <MainButton class="font-normal mt-8">Więcej</MainButton>
            </router-link>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<style>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}

.swiper-pagination-bullet-active {
  background: #fff;
}
</style>
