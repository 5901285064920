import { createWebHistory, createRouter as _createRouter } from 'vue-router'
import Home from '../views/HomeView.vue'
import NotFound from '../views/NotFoundView.vue'
import { useAuthStore } from '@/stores/auth'

export function createRouter() {
  const router = _createRouter({
    scrollBehavior(to) {
      const scroll = {}
      if (to.meta.toTop) scroll.top = 0
      return scroll
    },
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: { toTop: true }
      },
      {
        path: '/rejestracja',
        name: 'register',
        component: () => import('@/views/account/RegisterView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/resetuj-haslo',
        name: 'reset-password',
        component: () => import('@/views/account/ResetPasswordView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/logowanie',
        name: 'login',
        component: () => import('@/views/account/LoginView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/wizy',
        name: 'visas-step-1',
        component: () => import('@/views/visas/VisasView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/wizy/:country',
        name: 'visas-step-1-country',
        component: () => import('@/views/visas/VisasView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/wizy/krok-2',
        name: 'visas-step-2',
        component: () => import('@/views/visas/VisasView2.vue'),
        meta: { toTop: true }
      },
      {
        path: '/wizy/krok-3',
        name: 'visas-step-3',
        component: () => import('@/views/visas/VisasView3.vue'),
        meta: { toTop: true }
      },
      {
        path: '/wizy/krok-4',
        name: 'visas-step-4',
        component: () => import('@/views/visas/VisasView4.vue'),
        meta: { toTop: true }
      },
      {
        path: '/legalizacje',
        name: 'legalisations-step-1',
        component: () => import('@/views/legalisations/LegalisationsView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/legalizacje/:country',
        name: 'legalisations-step-1-country',
        component: () => import('@/views/legalisations/LegalisationsView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/legalizacje/krok-2',
        name: 'legalisations-step-2',
        component: () => import('@/views/legalisations/LegalisationsView2.vue'),
        meta: { toTop: true }
      },
      {
        path: '/legalizacje/krok-3',
        name: 'legalisations-step-3',
        component: () => import('@/views/legalisations/LegalisationsView3.vue'),
        meta: { toTop: true }
      },
      {
        path: '/legalizacje/krok-4',
        name: 'legalisations-step-4',
        component: () => import('@/views/legalisations/LegalisationsView4.vue'),
        meta: { toTop: true }
      },
      {
        path: '/dokumenty-i-uslugi',
        name: 'documents-step-1',
        component: () => import('@/views/documents/DocumentsView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/dokumenty-i-uslugi/:typeName',
        name: 'documents-step-1-type',
        component: () => import('@/views/documents/DocumentsView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/dokumenty-i-uslugi/krok-2',
        name: 'documents-step-2',
        component: () => import('@/views/documents/DocumentsView2.vue'),
        meta: { toTop: true }
      },
      {
        path: '/dokumenty-i-uslugi/krok-3',
        name: 'documents-step-3',
        component: () => import('@/views/documents/DocumentsView3.vue'),
        meta: { toTop: true }
      },
      {
        path: '/dokumenty-i-uslugi/krok-4',
        name: 'documents-step-4',
        component: () => import('@/views/documents/DocumentsView4.vue'),
        meta: { toTop: true }
      },
      {
        path: '/moje-konto',
        name: 'my-account',
        component: () => import('@/views/account/MyAccountView.vue'),
        meta: { toTop: true, requiresAuth: true }
      },
      {
        path: '/zamowienia',
        name: 'orders',
        component: () => import('@/views/account/OrdersView.vue'),
        meta: { toTop: true, requiresAuth: true }
      },
      {
        path: '/szczegoly-zamowienia/:uuid',
        name: 'orders-details',
        component: () => import('@/views/account/OrdersDetailsView.vue'),
        meta: { toTop: true, requiresAuth: true }
      },
      {
        path: '/potwierdz-konto/',
        name: 'confirm-account',
        component: () => import('@/views/account/ConfirmAccountView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/utworz-nowe-haslo',
        name: 'create-new-password',
        component: () => import('@/views/account/CreateNewPasswordView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/artykuly',
        name: 'articles',
        component: () => import('@/views/articles/ArticlesView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/artykuly/:slug',
        name: 'article-details',
        component: () => import('@/views/articles/ArticleDetails.vue'),
        meta: { toTop: true }
      },
      {
        path: '/potwierdzenie-zamowienia',
        name: 'order-confirmation',
        component: () => import('@/views/OrderConfirmationView.vue'),
        meta: { toTop: true }
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: { toTop: true }
      },
      {
        path: '/informacje/:slug',
        name: 'static-page',
        component: () => import('@/views/StaticPageView.vue'),
        meta: { toTop: true }
      }
    ]
  })
  router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore()
    if (to.meta.requiresAuth && !authStore.isSignedIn) {
      next({ name: 'login', query: { redirect: to.fullPath } })
    } else if (to.meta.requiresAuth && authStore.isSignedIn) {
      await authStore.refreshUserDataStore()
      if (authStore.isSignedIn) {
        next()
      } else {
        next({ name: 'login', query: { redirect: to.fullPath } })
      }
    } else {
      next()
    }
  })

  return router
}
