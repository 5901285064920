<script setup>
import { useRouter } from 'vue-router'
import MainButton from '@/components/MainButton.vue'

const router = useRouter()

const goToHome = () => {
  router.push('/')
}
</script>

<template>
  <div class="flex flex-row items-center justify-center space-x-20">
    <div>
      <h1 class="text-mainGrey-800 mb-2">Nie znaleziono strony</h1>
      <p class="text-sm text-mainGrey-600">
        Strona, której szukasz, nie istnieje lub została przeniesiona. Aby znaleźć interesujące Cię
        treści, przejdź na stronę główną.
      </p>
      <MainButton iconLeft text="Strona główna" @click="goToHome" class="mt-8" />
    </div>
    <div>
      <img src="../assets/not-found.svg" alt="404" class="w-max-[400px]" />
    </div>
  </div>
</template>
